import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";

export default function Framework(props) {
	return (
		<section className="container mt-[62px]">
			<div
				className="grid grid-cols-1 sm:grid-cols-12 gap-12"
				data-aos="fade-in"
				data-aos-offset="200"
				data-aos-delay="50"
				data-aos-duration="800"
				data-aos-easing="ease-in"
			>
				<div className="sm:col-span-7 flex items-center">
					<div>
						<p className="text-[48px] leading-[61px] font-lora font-normal">
							{props.topSection.title}
						</p>
						<p className="text-[20px] leading-[25px] mt-[29px] font-lora font-normal">
							{props.topSection.description}
						</p>
					</div>
				</div>
				<div className="sm:col-span-5 w-full sm:pl-10">
					<GatsbyImage
						image={props.topSection.thumbnail.asset.gatsbyImageData}
						alt="framework"
						placeholder="blurred"
					/>
				</div>
			</div>
		</section>
	);
}
