import React from "react";
import Accordion from "../Accordion";
import {GatsbyImage} from "gatsby-plugin-image";

export default function Courses({coursesSection}) {
	return (
		<section
			className="container mb-[60px]"
			data-aos="fade-in"
			data-aos-offset="200"
			data-aos-delay="50"
			data-aos-duration="800"
			data-aos-easing="ease-in"
		>
			{coursesSection.courses
				.filter((item) => item.featured)
				.map((item, index) => (
					<section
						className={"mt-20 sm:p-16 p-8"} key={index}
						style={{
							background: 'linear-gradient(90.94deg, #F4C943 10.33%, #FFFF95 95.02%)',
							borderRadius: 65
					}}
					>
						<div
							className="grid grid-cols-1 gap-12 sm:grid-cols-12"
							key={item.id}
						>
							<a
								data-thrivecart-account="nickwolny"
								data-thrivecart-tpl="v2"
								data-thrivecart-product={`${item.thriveCartProductID}`}
								id={`tcp${item.thriveCartProductID}`}
								className="hidden thrivecart-button"
							>
								Buy now!
							</a>
							<div className="sm:flex sm:items-center sm:col-span-7">
								<div>
									<p className="sm:text-[38px] text-[28px] sm:leading-[61px] leading-[40px] font-lora font-bold">
										{item.title}
									</p>
									<p className="text-[16px] leading-[25px] sm:mt-[29px] mt-[20px] font-lora font-normal">
										{item.detail}
									</p>
									<div className="flex items-center flex-wrap mt-[47px]">
										{/*<div*/}
										{/*	className="flex items-center py-[7px] pr-[13px] justify-between bg-gradient-to-r from-[#000000] to-[#333030] rounded-2xl">*/}
										{/*	<p className="text-white text-2xl leading-[30px] px-10 font-lora">*/}
										{/*		${item.price}*/}
										{/*	</p>*/}
										{/*	<a*/}
										{/*		href={"#!"}*/}
										{/*		// data-thrivecart-account="nickwolny"*/}
										{/*		// data-thrivecart-tpl="v2"*/}
										{/*		// data-thrivecart-product={item.thriveCartProductID}*/}
										{/*		onClick={(e) => {*/}
										{/*			e.preventDefault();*/}
										{/*			document*/}
										{/*				.getElementById(`tcp${item.thriveCartProductID}`)*/}
										{/*				.click();*/}
										{/*		}}*/}
										{/*		className={*/}
										{/*			"transition-all duration-300 uppercase bg-gradient-to-r hover:bg-gradient-to-l from-[#F4C943] to-[#FFFF95] text-black py-[18px] px-[26px] text-sm leading-[17px] font-lora hover:px-7 hover:text-yellow-900 rounded-2xl"*/}
										{/*		}*/}
										{/*	>*/}
										{/*		BUY NOW*/}
										{/*	</a>*/}
										{/*</div>*/}
										<div className="mt-5 sm:mt-0">
											<a
												href={item.learnMore}
												target={"_blank"}
												rel="noreferrer"
												className={
													"bg-black hover:bg-gray-800 text-base text-white py-[24px] px-[60px] md:px-[28px] lg:px-[47px] font-lora rounded-2xl"
												}
											>
												Learn More
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="w-full sm:col-span-5 sm:pl-10">
								<GatsbyImage
									image={item.thumbnail.asset.gatsbyImageData}
									alt="framework"
									placeholder="blurred"
								/>
							</div>
						</div>
					</section>
				))}

			<p className="text-[32px] leading-[40px] mb-[54px] font-lora font-normal text-center mt-[129px]">
				{coursesSection.title}
			</p>
			{coursesSection.courses
				.filter((item) => !item.featured)
				.map((item, index) => (
					<Accordion item={item} key={index}/>
				))}
		</section>
	);
}
