import React from "react";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { Script } from "gatsby";

export default function Hero(props) {
  return (
    <div className="relative sm:h-[640px] h-[740px]">
      <div className="absolute top-0 left-0 bottom-0 right-0 -z-20">
        <GatsbyImage
          image={props.heroSection.bgImage.asset.gatsbyImageData}
          alt="camp-hero"
          placeholder="blurred"
          className="sm:h-[640px] h-[740px] w-full"
        />
      </div>

      <div className="absolute top-0 left-10 bottom-0  right-0 -z-10">
        <StaticImage
          src="../../images/campground-overlay.png"
          alt="camp-hero"
          placeholder="blurred"
          className="sm:h-[640px] h-[740px]"
        />
      </div>

      <div className="container h-full text-white">
        <div className="flex items-center h-full">
          <div className="max-w-[674px]">
            <p className="text-[35px] sm:text-[64px] sm:leading-[82px] leading-[52px] font-lora font-medium">
              {props.heroSection.title}
            </p>
            <p className="mt-8 text-xl sm:text-[36px] sm:leading-[46px] leading-[36px] font-lora font-normal">
              {props.heroSection.subTitle}
            </p>
            <p className="mt-8 text-base sm:text-[20px] leading-[25px] font-lora font-normal">
              {props.heroSection.caption}
            </p>
            <div className="mt-8">
              <p className="text-[13.14px] leading-[15.4px] font-lora font-normal">
                Register for Free Video
              </p>
              <div className="_form_94"></div>
              <Script
                src="https://lessnoisemorevolume.activehosted.com/f/embed.php?id=94"
                type="text/javascript"
                charSet="utf-8"
              ></Script>
              {/*<div*/}
              {/*	className="bg-[url(./../images/input-border.svg)] flex items-center justify-between bg-no-repeat h-[62px] w-[300px] sm:w-[384px] mt-[11.89px]">*/}
              {/*	<input*/}
              {/*		placeholder="Enter your email address"*/}
              {/*		className="bg-transparent pl-6 outline-none"*/}
              {/*		onChange={(e) => console.log("e", e.target.value)}*/}
              {/*	/>*/}
              {/*	<button*/}
              {/*		className={*/}
              {/*			"transition-all duration-300 bg-gradient-to-r hover:bg-gradient-to-l from-[#F4C943] to-[#FFFF95] text-black py-3 px-10 hover:px-11 hover:text-yellow-900 rounded-lg"*/}
              {/*		}*/}
              {/*	>*/}
              {/*		Submit*/}
              {/*	</button>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
