import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Accordion({ item }) {
  const [isActive, setIsActive] = useState(false);
  return (
    <div
      className={`w-full duration-1000 ease-in-out transition-maxHeight mb-3 overflow-hidden ${
        isActive ? "max-h-fit" : "max-h-fit sm:max-h-fit"
      }`}
    >
      <a
        data-thrivecart-account="nickwolny"
        data-thrivecart-tpl="v2"
        data-thrivecart-product={`${item.thriveCartProductID}`}
        id={`tcp${item.thriveCartProductID}`}
        className="hidden thrivecart-button"
      >
        Buy now!
      </a>
      <div
        className="relative flex items-center justify-between py-[27px] rounded-[10px] cursor-pointer bg-[#d9d9d9]/[0.14] pl-2 sm:pl-[52px] pr-2 sm:pr-[34px]"
        onClick={() => setIsActive(!isActive)}
      >
        <p className="text-xl md:text-2xl font-medium text-black leading-[30px] font-lora">
          {item.title}
        </p>
        <div>
          {isActive ? (
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 7L7 1L13 7" stroke="black" />
            </svg>
          ) : (
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M13 1L7 7L1 0.999999" stroke="black" />
            </svg>
          )}
        </div>
      </div>

      <div className={`my-[27px] px-5 ${isActive ? "" : "hidden"}`}>
        <div className="grid grid-cols-1 md:grid-cols-12">
          <div className="flex justify-center md:col-span-3 md:justify-start">
            <div className="h-[206px] w-[206px] md:h-[170px] md:w-[170px] lg:h-[206px] lg:w-[206px] rounded-full bg-[#F9F9F9]">
              <GatsbyImage
                image={item.thumbnail.asset.gatsbyImageData}
                placeholder="blurred"
                alt="profitable offer"
                imgStyle={{ width: 128, height: 128, margin: "50px auto" }}
              />
            </div>
          </div>
          <div className="mt-5 ml-5 md:col-span-9">
            <p className="text-[18px] lg:text-[20px] leading-[24px] font-lora text-black/[0.94]">
              {item.detail}
            </p>
            <div className="flex items-center flex-col md:flex-row mt-[47px]">
              <div className="flex items-center w-[296px] py-[7px] pr-[13px] justify-between bg-gradient-to-r from-[#000000] to-[#333030] rounded-2xl">
                <p className="text-white text-2xl leading-[30px] ml-[42px] font-lora">
                  ${item.price}
                </p>
                <a
                  href={"#!"}
                  // data-thrivecart-account="nickwolny"
                  // data-thrivecart-tpl="v2"
                  // data-thrivecart-product={item.thriveCartProductID}
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById(`tcp${item.thriveCartProductID}`)
                      .click();
                  }}
                  className={
                    "transition-all duration-300 uppercase bg-gradient-to-r hover:bg-gradient-to-l from-[#F4C943] to-[#FFFF95] text-black py-[18px] px-[26px] text-sm leading-[17px] font-lora hover:px-7 hover:text-yellow-900 rounded-2xl"
                  }
                >
                  BUY NOW
                </a>
              </div>
              <div className="ml-[17px] mt-5 md:mt-0">
                <a
                  href={item.learnMore}
                  target={"_blank"}
                  rel="noreferrer"
                  className={
                    "bg-[#d9d9d9]/[0.38] text-base text-black py-[24px] px-[60px] md:px-[28px] lg:px-[47px] font-lora rounded-2xl"
                  }
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
